.text .text-content {
  overflow: hidden;
  padding: 5px 12px 5px 7px;
  font-style: italic;
  font-size: 10px;
  line-height: 1.2;
  color: #a0a0a0;
  border: 1px dashed #c0c0c0;
  white-space: pre-wrap;
}
.text .text-content .badge-container {
  position: absolute;
  top: -24px;
  right: calc(100% - 27px);
  width: 2.8rem;
  height: 2.8rem;
}
.text .text-content .badge-container .badge-checkbox {
  display: none;
  border: 1px solid #ddd;
  width: 1.9rem;
  height: 1.9rem;
  margin-top: 0.2rem;
  margin-left: 0.2rem;
}
.text .text-content .badge-container:hover .badge-checkbox {
  display: block;
}
.text .text-content .badge-container:hover .badge-icon {
  display: none;
}
.text .text-content .badge-container.selected .badge-checkbox {
  display: block;
}
.text .text-content .badge-container.selected .badge-icon {
  display: none;
}
.text .text-resizer {
  display: none;
  margin-top: -0.7rem;
  margin-left: -0.7rem;
  background: white;
}
.text .text-resizer i {
  transform: scaleX(-1);
  font-size: 0.72em;
  color: #a0a0a0;
  padding: 0.4em;
  background: #f8fafe;
  border-radius: 50%;
}
.text:hover .text-resizer {
  display: block;
}

