#use-cases {
  height: 100vh;
  width: 100%;
  overflow-y: auto;

  #use-cases-container {
    position: relative;
    top: 17%;
    margin-top: -5.5rem;

    img.workix-logo {
      height: 2.75rem;
    }

    #use-cases-title {
      span {
        font-size: 1.15em;
        color: #ffffffe0;
      }
    }

    .flow {
      margin-left: 0.27rem;
      margin-right: 0.27rem;
      border: 1px solid #d2dae0;

      div.avatar img {
        width: 4rem;
      }

      .flow-apps {
        i {
          color: #888;
        }
        span {
          color: #777;
        }
      }
    }
  }
}
