.screen-process-i, .screen-process-j {
  height: 4px;
  background-color: #2080E5;
  animation-duration: 2s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}
.screen-process-i {animation-name: anim_process_i;}
.screen-process-j {animation-name: anim_process_j;}

.screen-next-i, .screen-next-j {
  position: relative;
  display: block;
  animation-duration: 2s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}
.screen-next-i {animation-name: anim_next_i;}
.screen-next-j {animation-name: anim_next_j;}

.screen-click-i, .screen-click-j {
  animation-duration: 2s;
  animation-iteration-count: infinite;
}
.screen-click-i {animation-name: anim_click_i;}
.screen-click-j {animation-name: anim_click_j;}

.screen-drag-i, .screen-drag-j {
  animation-duration: 2s;
  animation-iteration-count: infinite;
}
.screen-drag-i {animation-name: anim_drag_i;}
.screen-drag-j {animation-name: anim_drag_j;}


.region-picker {
  max-height: 0px;
  transition: max-height 0.32s ease;

  &.active{
    max-height: 1000px;
    transition: max-height 0.32s ease;
  }
}


@keyframes anim_process_i {
  0% { width: 0; }
  100% { width: 100%; }
}

@keyframes anim_process_j {
  0% { width: 0; }
  100% { width: 100%; }
}


@keyframes anim_next_i {
  0% { opacity: 0; }
  55% { opacity: 0; }
  75% { opacity: 1; }
  99% { opacity: 1; }
  100% { opacity: 0; }
}
@keyframes anim_next_j {
  0% { opacity: 0; }
  55% { opacity: 0; }
  75% { opacity: 1; }
  99% { opacity: 1; }
  100% { opacity: 0; }
}


@keyframes anim_click_i {
  0% { transform: scale(0, 0); opacity: 0; }
  15% { transform: scale(0, 0); opacity: 0; }
  35% { transform: scale(1, 1); opacity: 1; }
  40% { transform: scale(1, 1); opacity: 1; }
  60% { transform: scale(0, 0); opacity: 0; }
  100% { transform: scale(0, 0); opacity: 0;  }
}

@keyframes anim_click_j {
  0% { transform: scale(0, 0); opacity: 0; }
  15% { transform: scale(0, 0); opacity: 0; }
  35% { transform: scale(1, 1); opacity: 1; }
  40% { transform: scale(1, 1); opacity: 1; }
  60% { transform: scale(0, 0); opacity: 0; }
  100% { transform: scale(0, 0); opacity: 0;  }
}

.MuiAutocomplete-root input{
  border-color: #bbb !important;
  color: #555 !important;
  padding: 5px 12px !important;
  min-width: 250px !important;
}