/*
__Seed builder__
  (Read_only) Component helper
*/

@import "_mixins";

.module {
  width: 100%;
  height: 100%;
}

.item {
  padding: 2px 0px;
}

.item input {
  margin-right: 7px;
}