.rich-text .tiptap {
  display: block;
  overflow-y: auto;
  width: 100%;
  height: auto;
  padding: 0.5rem 0.875rem;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.6;
  color: #1e2022;
  background-color: #fff;
  background-clip: padding-box;
  border: 0.0625rem solid #e7eaf3;
  border-radius: 0.3125rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  p{
    margin-bottom: 0px !important;
  }
  p.is-empty:first-child::before {
    color: #adb5bd;
    content: attr(data-placeholder);
    float: left;
    height: 0;
    pointer-events: none;
  }
}

.rich-text:hover, .rich-text:focus {
  .help-message{
    opacity: 1;
    transition: opacity 0.2s;
  }
}

.rich-text .help-message {
  position: absolute;
  opacity: 0;
  width: auto;
  bottom: 0px;
  right: 2.07em;
  font-size: 0.85em;
  padding: 0.1rem 0.7rem;
  background: #fff;
  color: #858a90;
  border: 1px solid #d5dae0;
  font-weight: bold;
  border-radius: 0.2rem;
}

.rich-text .variable {
  border: 1px solid #ccc;
  background: #fafbfc;
  padding: 2px 8px;
  font-size: 0.95em;
  border-radius: 10px;
  cursor: pointer;
}



.tippy-content .items {
  position: relative;
  border-radius: 0.5rem;
  background: #fff;
  color: rgba(0, 0, 0, 0.8);
  overflow: hidden;
  font-size: 0.9rem;
  max-height: 300px !important;
  overflow-y: auto;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05), 0px 10px 20px rgba(0, 0, 0, 0.1);
}

.tippy-content .list-group-item {
  &.hover:hover,
  &.selected {
    background-color: #dddddd !important;
  }
  &.subtitle {
    color: rgb(170, 170, 170);
    font-size: 0.7rem;
    text-transform: uppercase;
    letter-spacing: 0.03125rem;
    font-weight: 600;
    white-space: nowrap;
    padding-top: 0.7rem;
    padding-bottom: 0.7rem;
  }
}

.editor-submenu {
    width: 170px;
    position: absolute;
    z-index: 1000;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 0.5rem;
}

.editor-submenu-title {
    padding: 0.5rem 1rem;
    margin: 0rem 0.25rem;
    font-size: 0.7rem;
    font-weight: 600;
    border-bottom: 1px solid #ccc;
}

.editor-submenu-item {
    padding: 0.5rem 1rem;
    font-size: 0.8rem;
    cursor: pointer;
    &:hover {
        background-color: #f5f5f5;
    }
}

.tiptap {

  .table-border {
    td, th {
      border: 1px solid #e0e0e0;
    }
  }

  table {
    
    border-collapse: collapse;
    margin: 0;
    overflow: hidden;
    table-layout: fixed;
    width: 100%;

    td, th {
      box-sizing: border-box;
      min-width: 1em;
      padding: 6px 8px;
      position: relative;
      vertical-align: top;
      > * {
        margin-bottom: 0;
      }
    }

    .selectedCell:after {
      background: rgba(128, 128, 128, 0.1);
      content: "";
      left: 0; right: 0; top: 0; bottom: 0;
      pointer-events: none;
      position: absolute;
      z-index: 2;
    }

    .column-resize-handle {
      background-color: #f0f0f0;
      bottom: -2px;
      pointer-events: none;
      position: absolute;
      right: -2px;
      top: 0;
      width: 4px;
    }
  }

  .tableWrapper {
    margin: 1.5rem 0;
    overflow-x: auto;
  }

  &.resize-cursor {
    cursor: ew-resize;
    cursor: col-resize;
  }

}