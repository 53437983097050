.chart {
  border: 1px solid #e8e8e8;
  padding: 10px;
  margin: 10px 0;
  width: 100%;
  cursor: pointer;
}
.chart .chart-icon {
  width: 70%;
}
.chart:hover {
  border: 2px solid #007bff;
}
.chart.selected {
  border: 2px solid #007bff;
}

