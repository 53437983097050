#presentation-mode {
  max-width: 110rem;
  width: 100%;
  height: 100%;
  border: 1px solid #ddd;
  background: #f8fafe;
  overflow-y: auto;
  border-radius: 0.7rem;
}
#presentation-mode #presentation-header {
  padding: 0.65rem 2rem 0.65rem 1rem;
  background: white;
  border-bottom: 1px solid #e7eaf3;
}
#presentation-mode #presentation-header.read-mode .back-button,
#presentation-mode #presentation-header.read-mode #presentation-actions {
  display: none !important;
}
#presentation-mode #presentation-header.read-mode .logo {
  margin-left: 1.1rem;
}
#presentation-mode #presentation-header #presentation-title .back-button {
  cursor: pointer;
  font-size: 1.15em;
  height: 2.3rem;
  color: #778798;
  padding-right: 0.5rem;
  padding-left: 0.7rem;
}
#presentation-mode #presentation-header #presentation-title .back-button:hover {
  color: #2080e5;
  font-weight: bold;
}
#presentation-mode #presentation-header #presentation-title .logo {
  cursor: pointer;
  margin-top: -0.1rem;
  height: 4rem;
}
#presentation-mode #presentation-header #presentation-title .logo-workix {
  cursor: pointer;
  margin-top: -0.1rem;
  width: 7rem;
  height: 2.1rem;
  background-image: url("../../../../public/theme/svg/logos/logo.svg");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 7rem 2.1rem;
}
#presentation-mode #presentation-header #presentation-actions .avatar {
  cursor: pointer;
  user-select: none;
}
#presentation-mode #presentation-header #presentation-actions .idea-button {
  padding-right: 0.9rem;
  padding-left: 0.75rem;
}
#presentation-mode #presentation-header #presentation-actions .idea-button i {
  font-size: 1.4em;
}
#presentation-mode #presentation-header #presentation-actions .share-button {
  padding-right: 0.85rem;
  padding-left: 0.85rem;
}
#presentation-mode #presentation-iframe {
  height: 85vh;
  border: 0;
}
#presentation-mode #presentation-ctas-frame {
  position: absolute;
  top: 0.65rem;
  right: 9.4rem;
  height: 2.25rem;
  width: 19.55rem;
}

@media (max-width: 992px) {
  #presentation-mode {
    border-radius: 0rem;
  }
}
.exec-mode-tab {
  position: relative;
  color: #606570;
  font-size: 0.95rem;
  display: inline-block;
  border-bottom: 3.5px solid transparent;
  transition: border-bottom 0.2s, color 0.2s;
  border-radius: 0;
  padding: 0rem 0.4rem;
}
.exec-mode-tab.active, .exec-mode-tab:hover {
  border-bottom: 3.5px solid #2080e5;
  color: #2080e5;
  font-weight: bold;
}

#top-template #topnav header {
  background: #1a5080;
}
#top-template #topnav header #return-tab button {
  color: #fff;
  border-color: rgba(255, 255, 255, 0.5647058824);
}
#top-template #topnav header #control-tabs #edit_presentation_button {
  background: #f0b000;
  padding-right: 0.85rem;
  padding-left: 0.85rem;
}
#top-template #topnav #section-tabs {
  margin-top: 5rem;
}
#top-template #topnav .nav-badge {
  font-size: 1.6rem;
}
#top-template #topnav .nav-badge span {
  background-color: white;
  color: #132144;
  border: 1px solid #132144;
  font-weight: 100 !important;
}
#top-template #topnav .nav-badge.active span {
  background-color: #132144;
  color: white;
}
#top-template #topnav .nav-badge:hover span {
  background-color: #132144;
  color: white;
}

#side-template header#header #presentation-ctas #edit_presentation_button {
  background: #f0b000;
  padding-right: 0.85rem;
  padding-left: 0.85rem;
}
#side-template header#header #toggle-icon {
  visibility: hidden;
}

#section #collection-section {
  height: 630px;
  background-color: #fff;
}

