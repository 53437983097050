#scroll-arrow-main-container {
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
  margin-top: 0em;
  overflow-x: hidden;
}
#scroll-arrow-main-container .scroll-container {
  display: flex;
  overflow-x: auto;
  scroll-behavior: smooth;
  scrollbar-width: none;
}
#scroll-arrow-main-container .scroll-container ::-webkit-scrollbar {
  display: none;
}
#scroll-arrow-main-container div.scroll-btn {
  position: absolute;
  height: 100%;
  display: flex;
  align-items: center;
  border-radius: 0.75rem;
  cursor: pointer;
}
#scroll-arrow-main-container div.scroll-btn button {
  background: none;
  color: #7a858a;
  border: none;
  transition: background 0.3s;
  font-size: 1.1em;
  padding: 0.25rem 0.2rem;
}
#scroll-arrow-main-container div.scroll-btn button :hover {
  background: #f4faff;
}
#scroll-arrow-main-container div.left-btn {
  left: 0;
  z-index: 100;
}
#scroll-arrow-main-container div.left-btn button {
  margin-right: 0.25rem;
  margin-left: 0 !important;
}
#scroll-arrow-main-container div.right-btn {
  right: 0;
  z-index: 100;
}
#scroll-arrow-main-container div.right-btn button {
  margin-right: 0 !important;
  margin-left: 0.25rem;
}

