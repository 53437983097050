#start {
  height: 100vh;
  width: 100%;
  overflow-y: auto;
}
#start #start-container #start-logo {
  height: 2.6rem;
  margin-top: 0.4rem;
  margin-bottom: 0.7rem;
}
@media (max-width: 576px) {
  #start #start-container #start-logo {
    height: 2.4rem;
  }
}
#start #start-container #start-profile {
  background-color: #fff;
  border: 1px solid #d2dae0 !important;
  border-radius: 0.36rem;
  box-shadow: 0 6px 12px rgba(140, 152, 164, 0.075);
  padding: 0.3rem 0.8rem;
}
#start #start-container #start-profile:hover {
  background-color: #fff !important;
}
#start #start-container #start-panel {
  border: 1px solid #d2dae0;
}
#start #start-container #start-panel aside {
  border-radius: 10px;
}
#start #start-container #start-panel aside #start-panel-title {
  border-bottom: 0.12rem solid #e7eaf3;
  background: #f7faff;
  border-radius: 10px 10px 0px 0px;
}
#start #start-container #start-panel aside #start-panel-title .app-logo {
  padding-left: 1.25rem;
}
#start #start-container #start-panel aside #start-panel-title #account-settings #accountNavbarDropdown {
  width: 16rem;
  top: 4rem;
}
#start #start-container #start-panel aside #start-panel-title #account-settings #accountNavbarDropdown .dropdown-item {
  cursor: pointer;
}
#start #start-container #start-panel aside #start-panel-folders ul.folders-list {
  display: block;
}
#start #start-container #start-panel aside #start-panel-folders .create-folder-button {
  cursor: pointer;
  font-size: 0.97em;
}
#start #start-container #pending-executions {
  border: 1px solid #d2dae0;
}
#start #start-container #pending-executions #executions-container {
  max-height: 9.8rem;
  min-height: 1.29rem;
  overflow-y: auto;
}
#start #start-container #start-templates {
  border: 1px solid #d2dae0;
  overflow: hidden;
}
#start #start-container #start-templates #background-hero {
  height: 100%;
  width: 100%;
}
#start #start-container #start-templates #start-templates-container {
  padding: 2.85rem 1.5rem 2.25rem 1.5rem;
}
#start #start-container #start-templates #start-templates-container #start-templates-title {
  color: #405060;
}
#start #start-container #start-templates #start-templates-container #start-templates-search #start-templates-search-input {
  width: 75%;
}
#start #start-container #start-templates #start-templates-container #start-templates-search #start-templates-search-input input {
  border: 1px solid #dae0e5;
  background: white;
}
#start #start-container #start-templates #start-templates-container #start-templates-search #start-templates-search-button {
  width: 25%;
}
#start #start-container #start-flows-list .nav-sub {
  margin-left: 2.4rem;
}
#start #start-container #start-flows-list .nav-sub .nav-link {
  border-left: 2px solid #eaf0f5;
  padding-left: 1.5rem;
}
#start #start-container #start-flows {
  background-color: #f7fafe;
  border: 1px solid #d2dae0;
}
#start #start-container #start-flows #start-flows-container .card {
  border: 1px solid #d2dae0;
}
#start #start-container #start-flows #start-flows-container #flows #flows-container {
  margin-top: 0.75rem;
}
#start #start-container #start-flows #start-flows-container #flows #flows-container .flow {
  width: 32.1%;
  min-width: 32.1%;
  margin-right: 0.8rem;
  margin-bottom: 0.8rem;
}
#start #start-container #start-flows #start-flows-container #flows #flows-container .flow.large {
  width: 48.85%;
  min-width: 48.85%;
}
#start #start-container #start-flows #start-flows-container #flows #flows-container .flow.large:nth-child(2n) {
  margin-right: 0rem;
}
#start #start-container #start-flows #start-flows-container #flows #flows-container .flow:hover .h4 {
  color: #2080e5 !important;
  transition: color 0.4s;
}
#start #start-container #start-flows #start-flows-container #flows #flows-container .flow .flow-settings button {
  font-size: 1.2em;
  border: none;
}
#start #start-container #start-flows #start-flows-container #flows #flows-container .flow .flow-settings button i {
  color: #909090;
}
@media (min-width: 1000px) {
  #start #start-container #start-flows #start-flows-container #flows #flows-container .flow:nth-child(3n) {
    margin-right: 0rem;
  }
}
@media (max-width: 1000px) {
  #start #start-container #start-flows #start-flows-container #flows #flows-container .flow {
    width: 48%;
    min-width: 48%;
  }
  #start #start-container #start-flows #start-flows-container #flows #flows-container .flow:nth-child(2n) {
    margin-right: 0rem;
  }
}
@media (max-width: 576px) {
  #start #start-container #start-flows #start-flows-container #flows #flows-container .flow {
    width: 99.2%;
    min-width: 99.2%;
  }
  #start #start-container #start-flows #start-flows-container #flows #flows-container .flow:nth-child(1n) {
    margin-right: 0rem;
  }
}
#start #start-container #start-flows #start-flows-container #flows #see-more-flows {
  border-color: rgba(231, 234, 243, 0.7);
}
#start #start-container #start-flows #start-flows-container #no-flows #no-flows-title {
  color: #708090;
  font-size: 1.05rem;
  margin-bottom: 1.25rem;
}
#start #start-container #start-flows #start-flows-container #no-flows a i {
  font-size: 1em;
}
#start #start-container #heatmap-usage {
  background-color: #f7fafe;
  border: 1px solid #d2dae0;
}
#start #start-container #heatmap-usage #heatmap-usage-container {
  padding: 1.5rem 0.7rem 0rem 0.7rem;
}
#start #start-container #heatmap-usage #heatmap-usage-container #heatmap-usage-title {
  color: #606570;
  font-size: 0.87rem;
  padding: 0rem 0.7rem;
}
#start #start-container #recent-executions {
  border: 1px solid #d2dae0;
}
#start #start-container #recent-executions #recent-executions-container {
  padding: 1.5rem 1.5rem 1.2rem 1.5rem;
}
#start #start-container #recent-executions #recent-executions-container #recent-executions-title {
  color: #808590;
  font-size: 0.95em;
  margin-bottom: 0.7rem;
}
#start #start-container #executions-list .no-data-legend span {
  color: #b0b2b2;
  font-size: 0.95em;
}
#start .back-button-flow {
  display: block;
  width: 2.15rem;
  height: 2.15rem;
  background-image: url("../../../../public/theme/svg/logos/logo-short.svg");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 2.15rem;
  cursor: default;
}
#start .navbar-vertical-content .nav-link {
  color: #6a7080 !important;
}
#start .nav-link.active i, #start .nav-link.active span,
#start .nav-link:hover i, #start .nav-link:hover span {
  color: #2080e5;
}

#start-nav aside {
  border-radius: 10px;
}
#start-nav #start-nav-header {
  border-bottom: 0.12rem solid #e7eaf3;
  background: #f7fafe;
  border-radius: 10px 10px 0px 0px;
}
#start-nav #start-nav-header #accountNavbarDropdown {
  width: 16rem;
  top: 4rem;
}
#start-nav #start-icon {
  display: block;
  width: 2.4rem;
  height: 2.4rem;
  margin-left: 1.15rem;
  background-image: url("../../../../public/theme/svg/logos/logo-short.svg");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 2.4rem;
  cursor: default;
}

#billing #billing-container {
  border: none;
  box-shadow: none;
}
#billing #billing-container #plan-details .name {
  font-weight: normal;
}

#flows #no-flows {
  font-size: 1rem;
}
#flows #no-flows i {
  font-size: 2.7rem;
  color: #5a606a;
}

#external-apps #external-apps-body #integration-accounts .integration-account .integration-settings button {
  font-size: 1em;
  border: none;
}
#external-apps #external-apps-body #integration-accounts .integration-account .integration-settings button i {
  color: #909090;
}

