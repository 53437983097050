#tables {
  width: 100%;
  height: 100%;
  overflow: hidden;

  #tables-header {
    position: relative;
    margin-right: 2rem;

    #collection-tabs {
      overflow-x: auto;

      .collection-tab {
        cursor: pointer;
        background-color: #fff;
        border: 1px solid #ddd;
        color: #6a7075;
        transition: background-color 0.3s, color 0.3s;
        max-width: 16rem;

        &:hover {
          background-color: #fff;
          color: #111;
        }

        &.active {
          background-color: #2080e5;
          color: #fff;
          font-weight: bold;

          .tab-icon {
            color: #fff !important;
          }
        }

        .collection-options {
          .collection-options-content {
            .dropdown-item:not(:last-child) {
              border-right: 1px solid #ddd;
            }
          }
        }
      }

      #add-collection-tab {
        position: relative;

        #create-table-dropdown {
          span {
            color: #6a7075;
            width: 5.7rem;
          }
        }

        #create-table-dropdown-content {
          position: fixed;
          z-index: 4000 !important;

          & > div {
            position: absolute;
          }
        }
      }
    }
  }

  #tables-body {
    margin-top: 1.2rem;

    #table-title{
      position: relative;
      z-index: 100;
    }
    #tables-board {
      #table-controls {
        margin-top: -3.1rem !important;
        #table-search-input {
          & > div {
            border: 1px solid #dadde0;
          }

          input {
            width: 10rem;
          }

          i {
            cursor: pointer;
          }
        }

        #table-search-button {
          width: 2.2rem;
        }

        #table-control-buttons {
          button#table-filter-button {
            min-width: "2.2rem";
          }

          #dropdown-options {
            width: 2.2rem;
          }
        }
      }

      #collection-table {
        position: relative;
        overflow-y: auto;
        overflow-x: auto;
        width: calc(100% - 10px);

        #loading-content {
          width: calc(100% - 100px);
          height: calc(100% - 200px);
        }

        table {
          width: auto;

          th {
            padding-top: 0rem !important;
            padding-bottom: 0rem !important;
            height: 36px !important;
          }

          td {
            height: 36px !important;
            padding-top: 0rem !important;
            padding-bottom: 0rem !important;
          }

          tbody {
            border-top: 2px solid #000;
            background-color: #fff;
          }

          th.table-column {
            position: relative;
            min-width: 210px;
            max-width: 450px;
            height: 40px;
          }

          .table-column-content {
            max-width: 100%;

            .column-name {
              max-width: 300px;

              span {
                font-size: 0.95em;
              }
            }

            .column-type {
              color: #858a90;
            }

            .column-options-icon {
              cursor: pointer;
              font-size: 0.8em;
            }
          }

          .dropdown-column-settings {
            position: absolute;
            z-index: 1000;
          }

          th.add-column {
            cursor: pointer;

            .dropdown-header {
              color: #808590;
            }
          }

          td.folio-column {
            background: #f8fafe;

            span {
              cursor: pointer;
            }
          }

          td.table-record {

            max-width: 450px;
            position: relative;
            background-color: #fff;

            .file-field {
              cursor: pointer;
              border-radius: 5px;

              .file-field-content {
                border: 1px solid #e0e0e0;
                width: 75%;
                border-radius: 0.25rem;
                cursor: pointer;

                i {
                  font-size: 1.2em;
                }
              }
            }

            .boolean-field {
              cursor: pointer;
              border-radius: 5px;

              input {
                cursor: pointer;
              }
            }

            .collection-field {
              cursor: pointer;
              border-radius: 5px;
            }

            .enum-field {
            }
          }

          td.delete-row-button {
            background-color: #fff;
            cursor: pointer;
          }
          
          td.edit-row-button {
            background-color: #fff;
            cursor: pointer;
          }

        }
      }

      #collection-pagination {
        nav {
          overflow-x: auto;
        }
      }
    }
  }

}