.react-flow__node-pipe {
  pointer-events: none !important;
}

.pipe {
  overflow: hidden;
  border: 1px solid #c0c0c0;
  color: #a0a0a0;
  font-style: italic;
  font-size: 11px;
  line-height: 1.2;

  .pipe-title {
    position: relative;
    text-align: center;
    background-color: white;
    font-weight: bold;
    pointer-events: auto;
  }

  .pipe-border {
    position: absolute;
    pointer-events: auto;
  }

  .pipe-resizer {
    position: absolute;
    display: none;
    background: white;

    i {
      transform: scaleX(-1);
      font-size: 1.1em;
      color: #a0a0a0;
      padding: 0.4em;
      background: #f8fafe;
      border-radius: 50%;
    }
  }

  &:hover {
    .pipe-resizer {
      display: block;
    }
  }

  &.horizontal {
    border-right-width: 0;

    .pipe-border {
      width: 100%;
    }

    .pipe-border-a {
      height: 1.25rem;
      top: -0.75rem;
    }

    .pipe-border-b {
      height: 1.25rem;
      bottom: -0.85rem;
    }

    .pipe-title {
      border-right: 1px solid #c0c0c0;
      height: 100%;
      width: 1.7rem;
      padding: 0.2rem;

      .pipe-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        writing-mode: vertical-lr;
        height: 100%;
        width: 100%;
        transform: rotate(180deg);
      }
    }

    .pipe-resizer {
      margin-top: -1.2rem;
      margin-left: -0.7rem;
    }
  }

  &.vertical {
    border-bottom-width: 0;

    .pipe-border {
      height: 100%;
      top: 0px;
    }

    .pipe-border-a {
      width: 1.25rem;
      left: -0.75rem;
    }

    .pipe-border-b {
      width: 1.25rem;
      right: -0.85rem;
    }

    .pipe-title {
      border-bottom: 1px solid #c0c0c0;
      width: 100%;
      height: 1.7rem;
      padding: 0.3rem;
    }

    .pipe-resizer {
      margin-top: -1rem;
      margin-left: -1.3rem;
    }
  }
}
