div.node {
  background-color: white;
  border: 1px solid #c0c0c0;
  border-radius: 5%;
}

div.trigger {
  padding: 5px 8px 8px 0px;
  background-image: linear-gradient(45deg,
      transparent 0%,
      #c0c0c0 0% 90%,
      transparent 90%);
}

div.node,
div.trigger {
  .node-idx {
    position: absolute;
    right: -7px;
    bottom: -9px;
    z-index: 2;

    span {
      font-size: 0.64rem;
      color: #80858a;
      padding: 0px 4.5px;
      background: #fff;
      border: 1px solid #c0c0c0;
      border-radius: 3px;
    }
  }

  .exec-button {
    position: absolute;
    cursor: pointer;

    span.node-execute {
      border: 1px solid #00b797;
      padding: 0.35rem 0.6rem 0.35rem 0.6rem;
      font-size: 0.74rem;

      i {
        margin-right: 0.2rem;
        font-size: 0.95em;
      }
    }
  }

  .exec-message-icon {
    position: absolute;
    cursor: pointer;

    i {
      color: #2080e5;
      cursor: pointer;
      margin-left: 0.5rem;
      font-size: 1.05em;
    }
  }

  .execution-message {
    min-width: 200px;
  }

  .trigger-active-switch {
    label.custom-control-label {
      span {
        font-size: 0.77em;
        margin-top: 0.12rem;
      }
    }
  }
}



div.node,
div.trigger,
div.control-node {

  $insert-child-size: 28px;
  $insert-child-separation: 11px;
  $insert-child-pos-a: -2*$insert-child-separation - $insert-child-size;
  $insert-child-pos-b: calc(50% - ($insert-child-size/2 + $insert-child-separation));
  $insert-child-conditional-pos-a: $insert-child-pos-a - 7px;
  $insert-child-conditional-pos-b: calc(50% - ($insert-child-size/2 + $insert-child-separation) + 7px);
  $insert-child-wrapper-size: $insert-child-size + 2 * $insert-child-separation;
  $insert-child-wrapper-padding: $insert-child-separation;

  $insert-child-sm-size: 15px;
  $insert-child-sm-separation: 15px;
  $insert-child-sm-pos-a: -2*$insert-child-sm-separation - $insert-child-sm-size;
  $insert-child-sm-pos-b: calc(50% - ($insert-child-sm-size/2 + $insert-child-sm-separation));
  $insert-child-sm-conditional-pos-a: $insert-child-sm-pos-a - 7px;
  $insert-child-sm-conditional-pos-b: calc(50% - ($insert-child-sm-size/2 + $insert-child-sm-separation) + 4px);
  $insert-child-sm-wrapper-padding: $insert-child-sm-separation;


  div.insert-child-wrapper {
    position: absolute;
    padding: $insert-child-sm-wrapper-padding;
    opacity: 0.3;

    &.bottom {
      bottom: $insert-child-sm-pos-a;
      left: $insert-child-sm-pos-b;

      &.conditional {
        bottom: $insert-child-sm-conditional-pos-a;
        left: $insert-child-sm-conditional-pos-b;
      }
    }

    &.right {
      right: $insert-child-sm-pos-a;
      top: $insert-child-sm-pos-b;

      &.conditional {
        right: $insert-child-sm-conditional-pos-a;
        top: $insert-child-sm-conditional-pos-b;
      }
    }

    &.left {
      left: $insert-child-sm-pos-a;
      top: $insert-child-sm-pos-b;

      &.conditional {
        left: $insert-child-sm-conditional-pos-a;
        top: $insert-child-sm-conditional-pos-b;
      }
    }

    &.top {
      top: $insert-child-sm-pos-a;
      left: $insert-child-sm-pos-b;

      &.conditional {
        top: $insert-child-sm-conditional-pos-a;
        left: $insert-child-sm-conditional-pos-b;
      }
    }

    &.active {
      .insert-child {
        background-color: #2080e5 !important;
        border: none !important;
        color: white !important;
        transition: background-color 0.4s;
      }
    }

    .insert-child {
      height: $insert-child-sm-size;
      width: $insert-child-sm-size;
      font-size: 0.5rem;
      display: flex;
      border-radius: 50%;
      align-items: center;
      justify-content: center;
      z-index: 100 !important;
      cursor: pointer;
  
      background-color: white;
      border: 1px solid #c0c0c0;
      color: #8590a0;
  
      &:hover {
        background-color: #2080e5 !important;
        border: none !important;
        color: white !important;
        transition: background-color 0.4s;
      }
    }
  
  }

  div.insert-child-wrapper.default,
  div.insert-child-wrapper:hover {
    padding: $insert-child-wrapper-padding;
    transition: top 0.35s, left 0.35s, bottom 0.35s, right 0.35s;
    opacity: 1;

    &.bottom {
      bottom: $insert-child-pos-a;
      left: $insert-child-pos-b;

      &.conditional {
        bottom: $insert-child-conditional-pos-a;
        left: $insert-child-conditional-pos-b;
      }
    }

    &.right {
      right: $insert-child-pos-a;
      top: $insert-child-pos-b;

      &.conditional {
        right: $insert-child-conditional-pos-a;
        top: $insert-child-conditional-pos-b;
      }
    }

    &.left {
      left: $insert-child-pos-a;
      top: $insert-child-pos-b;

      &.conditional {
        left: $insert-child-conditional-pos-a;
        top: $insert-child-conditional-pos-b;
      }
    }

    &.top {
      top: $insert-child-pos-a;
      left: $insert-child-pos-b;

      &.conditional {
        top: $insert-child-conditional-pos-a;
        left: $insert-child-conditional-pos-b;
      }
    }

    .insert-child {
      height: $insert-child-size;
      width: $insert-child-size;
      font-size: 0.75rem;
    }
  }  
}


div.control-node {
  .control-node-container {
    background-color: white;
    transform: rotate(45deg);
    border: 1px solid #c0c0c0;
    padding: 15px 14px;
    width: 40px;
    height: 40px;

    .node-content {
      transform: rotate(-45deg) translate(-7px, -7px);
    }
  }

  .warnings-errors-control-node {
    .dropdown-icons {

      .errors,
      .warnings {
        position: absolute;
        top: calc(100% - 45px);
        right: -20px;
        cursor: pointer;
      }
    }
  }
}

.trigger-radiobutton:checked~.custom-control-label::before {
  background-color: #a0b0c0;
  border-color: #a0a0a0;
}

.warnings-errors {
  .dropdown-icons {

    .errors,
    .warnings {
      position: absolute;
      top: calc(50% - 27px);
      right: -21px;
      cursor: pointer;
    }
  }
}

div.trigger::before {
  content: "";
  position: absolute;
  left: 1px;
  right: 1px;
  top: 1px;
  bottom: 1px;
  background-image: linear-gradient(45deg,
      transparent 0%,
      white 0% 90%,
      transparent 90%);
}

.node-content {
  position: relative;

  hr {
    border-top-color: #eee;
  }

  .node-subtitle {
    font-size: 0.7rem;

    label {
      font-size: 0.9em;
      color: #909090;
    }

    i {
      font-size: 0.8rem;
    }
  }

  .control-node-subtitle {
    position: absolute;
    top: -45px;

    div.control-node-subtitle-message {
      border: 1px solid #c0c0c0;
      font-size: 10px;
      color: #808080;
      font-weight: normal;
      line-height: 1.15;

      &>div {
        min-width: 5.5rem;
        max-width: 7rem;
        white-space: normal;
        max-height: 2.4rem;
        overflow: hidden;
      }
    }
  }

  .node-icon-app {
    .select-node-checkbox {
      border: 1px solid #ddd;
      width: 1.9rem;
      height: 1.9rem;
      margin-top: 0.35rem;
      margin-left: 0.35rem;
    }

    .node-icon {
      border: 1px solid #c0c0c0;
      width: 1.4rem;
      height: 1.4rem;
      font-size: 12.5px;
      padding-top: 0.32rem;
      margin-top: 0.6rem;
      margin-left: -0.2rem;
      border-radius: 4px;
      background-color: white;

      i {
        color: #909090;
      }
    }
  }

  .iterable-legend {
    position: absolute;

    span {
      border: 1px solid #c0c0c0;
    }
  }
}

.badge-container .badge-checkbox {
  display: none;
}

.badge-container.selected .badge-checkbox {
  display: block;
}

.badge-container.selected .badge-icon {
  display: none;
}

.badge-container:hover .badge-checkbox {
  display: block;
}

.badge-container:hover .badge-icon {
  display: none;
}

.execution-message {
  background: #fff;
  border: 1px solid #e0e5ea;
  padding: 0.75rem;
  color: #40454a;
  max-width: 14rem;
  color: #40454a;
  border-radius: 10px;
  max-width: 12rem;
  font-size: 0.67rem;
  line-height: 1.5;
  top: 0px;
  right: 0px;
  left: auto;
}

.execution-message.execution-trigger {
  margin-top: 1.5rem !important;
  margin-left: -1.5rem !important;
}

.execution-message.execution-node {
  margin-right: -10rem;
}

.react-flow__node:only-child:has(.node-idx), .react-flow__node:nth-last-child(2):has(.node-idx) {
  // Padding to align initial nodes
  padding-bottom: 2.5rem;
  padding-right: 1.5rem;
}

.react-flow__handle {
  z-index: 1001 !important;
  min-height: 16px !important;
  min-width: 16px !important;
  width: 16px !important;
  height: 16px !important;
  background: none !important;
  border: none !important;
  display: flex !important;
  align-items: center;
  justify-content: center;

  &.react-flow__handle-top {
    top: -9px;
  }

  &.react-flow__handle-bottom {
    bottom: -9px;
  }

  &.react-flow__handle-right {
    right: -9px;
  }

  &.react-flow__handle-left {
    left: -9px;
  }
}

.react-flow__handle::after {
  content: " ";
  position: absolute;
  background-color: #c0c0c0;
  display: inline-block;
  border-radius: 50%;
  width: 6px;
  height: 6px;
  border: 1px solid #fff;
  top: 5px;
  left: 5px;
}

.cta-iframe-viewer,
.cta-create-node {
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}

.cta-delete-node {
  cursor: pointer;
  float: right;
  border: 1px solid #f0f0f0;
  color: #a0a0a0;
  padding: 3px;
  margin-top: 5px;
}