#scroll-arrow-main-container {
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
  margin-top: 0em;
  overflow-x: hidden;

  .scroll-container {
    display: flex;
    overflow-x: auto;
    scroll-behavior: smooth;
    scrollbar-width: none;

    ::-webkit-scrollbar {
      display: none;
    }
  }

  div.scroll-btn {
    position: absolute;
    height: 100%;
    display: flex;
    align-items: center;
    border-radius: 0.75rem;
    cursor: pointer;

    button {
      background: none;
      color: #7a858a;
      border: none;
      transition: background 0.3s;
      font-size: 1.1em;
      padding: 0.25rem 0.2rem;

      :hover {
        background: #f4faff;
      }
    }
  }

  div.left-btn {
    left: 0;
    z-index: 100;

    button {
      margin-right: 0.25rem;
      margin-left: 0 !important;
    }
  }

  div.right-btn {
    right: 0;
    z-index: 100;

    button {
      margin-right: 0 !important;
      margin-left: 0.25rem;
    }
  }
}
