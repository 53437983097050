#settings {
  height: 100vh;
  width: 100%;
  overflow-y: auto;
}
#settings #start-settings aside {
  border-radius: 10px;
}
#settings #start-settings #start-settings-header {
  border-bottom: 0.12rem solid #e7eaf3;
  background: #f7fafe;
  border-radius: 10px 10px 0px 0px;
}
#settings #start-settings #start-settings-header #accountNavbarDropdown {
  width: 16rem;
  top: 4rem;
}
#settings #start-settings .back-button-flow {
  display: block;
  width: 2.3rem;
  height: 2.3rem;
  background-image: url("../../../../public/theme/svg/logos/logo-short.svg");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 2.3rem;
  cursor: default;
}
#settings #start-settings .navbar-vertical-content .nav-link {
  border: none !important;
  color: #6a7080 !important;
}
#settings #start-settings .navbar-vertical-content .nav-link.active,
#settings #start-settings .navbar-vertical-content .nav-link:hover {
  color: #2080e5 !important;
}
#settings #billing #billing-container {
  border: none;
  box-shadow: none;
}
#settings #billing #billing-container #plan-details .name {
  font-weight: normal;
}
#settings #flows #no-flows {
  font-size: 1rem;
}
#settings #flows #no-flows i {
  font-size: 2.7rem;
  color: #5a606a;
}
#settings #external-apps #external-apps-body #integration-accounts .integration-account .integration-settings button {
  font-size: 1em;
  border: none;
}
#settings #external-apps #external-apps-body #integration-accounts .integration-account .integration-settings button i {
  color: #909090;
}

