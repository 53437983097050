/*
__Seed builder__
  (Read_only) Component helper
*/
/*
__Seed builder__
  (Read_only) Builder helper
*/
.module {
  position: fixed;
  z-index: 1300;
  inset: 0px;
  background-color: rgba(0, 0, 0, 0.5);
}

.container {
  position: relative;
  top: 50%;
  left: 50%;
}

.close {
  position: absolute;
  color: #fff;
  width: 35px;
  height: 35px;
  margin-top: -26px;
  border: none;
  background: none;
  font-size: 1.2rem;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
}

.content {
  width: 100%;
  height: 100%;
}

