
.chart {
    border: 1px solid #e8e8e8;
    padding: 10px;
    margin: 10px 0;
    // height: 200px;
    width: 100%;
    cursor: pointer;

    .chart-icon {
        width: 70%;

    }

    &:hover {
        border: 2px solid #007bff;

    }

    &.selected {
        border: 2px solid #007bff;
    }
}