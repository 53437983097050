div.node {
  background-color: white;
  border: 1px solid #c0c0c0;
  border-radius: 5%;
}

div.trigger {
  padding: 5px 8px 8px 0px;
  background-image: linear-gradient(45deg, transparent 0%, #c0c0c0 0% 90%, transparent 90%);
}

div.node .node-idx,
div.trigger .node-idx {
  position: absolute;
  right: -7px;
  bottom: -9px;
  z-index: 2;
}
div.node .node-idx span,
div.trigger .node-idx span {
  font-size: 0.64rem;
  color: #80858a;
  padding: 0px 4.5px;
  background: #fff;
  border: 1px solid #c0c0c0;
  border-radius: 3px;
}
div.node .exec-button,
div.trigger .exec-button {
  position: absolute;
  cursor: pointer;
}
div.node .exec-button span.node-execute,
div.trigger .exec-button span.node-execute {
  border: 1px solid #00b797;
  padding: 0.35rem 0.6rem 0.35rem 0.6rem;
  font-size: 0.74rem;
}
div.node .exec-button span.node-execute i,
div.trigger .exec-button span.node-execute i {
  margin-right: 0.2rem;
  font-size: 0.95em;
}
div.node .exec-message-icon,
div.trigger .exec-message-icon {
  position: absolute;
  cursor: pointer;
}
div.node .exec-message-icon i,
div.trigger .exec-message-icon i {
  color: #2080e5;
  cursor: pointer;
  margin-left: 0.5rem;
  font-size: 1.05em;
}
div.node .execution-message,
div.trigger .execution-message {
  min-width: 200px;
}
div.node .trigger-active-switch label.custom-control-label span,
div.trigger .trigger-active-switch label.custom-control-label span {
  font-size: 0.77em;
  margin-top: 0.12rem;
}

div.node div.insert-child-wrapper,
div.trigger div.insert-child-wrapper,
div.control-node div.insert-child-wrapper {
  position: absolute;
  padding: 15px;
  opacity: 0.3;
}
div.node div.insert-child-wrapper.bottom,
div.trigger div.insert-child-wrapper.bottom,
div.control-node div.insert-child-wrapper.bottom {
  bottom: -45px;
  left: calc(50% - 22.5px);
}
div.node div.insert-child-wrapper.bottom.conditional,
div.trigger div.insert-child-wrapper.bottom.conditional,
div.control-node div.insert-child-wrapper.bottom.conditional {
  bottom: -52px;
  left: calc(50% - 22.5px + 4px);
}
div.node div.insert-child-wrapper.right,
div.trigger div.insert-child-wrapper.right,
div.control-node div.insert-child-wrapper.right {
  right: -45px;
  top: calc(50% - 22.5px);
}
div.node div.insert-child-wrapper.right.conditional,
div.trigger div.insert-child-wrapper.right.conditional,
div.control-node div.insert-child-wrapper.right.conditional {
  right: -52px;
  top: calc(50% - 22.5px + 4px);
}
div.node div.insert-child-wrapper.left,
div.trigger div.insert-child-wrapper.left,
div.control-node div.insert-child-wrapper.left {
  left: -45px;
  top: calc(50% - 22.5px);
}
div.node div.insert-child-wrapper.left.conditional,
div.trigger div.insert-child-wrapper.left.conditional,
div.control-node div.insert-child-wrapper.left.conditional {
  left: -52px;
  top: calc(50% - 22.5px + 4px);
}
div.node div.insert-child-wrapper.top,
div.trigger div.insert-child-wrapper.top,
div.control-node div.insert-child-wrapper.top {
  top: -45px;
  left: calc(50% - 22.5px);
}
div.node div.insert-child-wrapper.top.conditional,
div.trigger div.insert-child-wrapper.top.conditional,
div.control-node div.insert-child-wrapper.top.conditional {
  top: -52px;
  left: calc(50% - 22.5px + 4px);
}
div.node div.insert-child-wrapper.active .insert-child,
div.trigger div.insert-child-wrapper.active .insert-child,
div.control-node div.insert-child-wrapper.active .insert-child {
  background-color: #2080e5 !important;
  border: none !important;
  color: white !important;
  transition: background-color 0.4s;
}
div.node div.insert-child-wrapper .insert-child,
div.trigger div.insert-child-wrapper .insert-child,
div.control-node div.insert-child-wrapper .insert-child {
  height: 15px;
  width: 15px;
  font-size: 0.5rem;
  display: flex;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  z-index: 100 !important;
  cursor: pointer;
  background-color: white;
  border: 1px solid #c0c0c0;
  color: #8590a0;
}
div.node div.insert-child-wrapper .insert-child:hover,
div.trigger div.insert-child-wrapper .insert-child:hover,
div.control-node div.insert-child-wrapper .insert-child:hover {
  background-color: #2080e5 !important;
  border: none !important;
  color: white !important;
  transition: background-color 0.4s;
}
div.node div.insert-child-wrapper.default,
div.node div.insert-child-wrapper:hover,
div.trigger div.insert-child-wrapper.default,
div.trigger div.insert-child-wrapper:hover,
div.control-node div.insert-child-wrapper.default,
div.control-node div.insert-child-wrapper:hover {
  padding: 11px;
  transition: top 0.35s, left 0.35s, bottom 0.35s, right 0.35s;
  opacity: 1;
}
div.node div.insert-child-wrapper.default.bottom,
div.node div.insert-child-wrapper:hover.bottom,
div.trigger div.insert-child-wrapper.default.bottom,
div.trigger div.insert-child-wrapper:hover.bottom,
div.control-node div.insert-child-wrapper.default.bottom,
div.control-node div.insert-child-wrapper:hover.bottom {
  bottom: -50px;
  left: calc(50% - 25px);
}
div.node div.insert-child-wrapper.default.bottom.conditional,
div.node div.insert-child-wrapper:hover.bottom.conditional,
div.trigger div.insert-child-wrapper.default.bottom.conditional,
div.trigger div.insert-child-wrapper:hover.bottom.conditional,
div.control-node div.insert-child-wrapper.default.bottom.conditional,
div.control-node div.insert-child-wrapper:hover.bottom.conditional {
  bottom: -57px;
  left: calc(50% - 25px + 7px);
}
div.node div.insert-child-wrapper.default.right,
div.node div.insert-child-wrapper:hover.right,
div.trigger div.insert-child-wrapper.default.right,
div.trigger div.insert-child-wrapper:hover.right,
div.control-node div.insert-child-wrapper.default.right,
div.control-node div.insert-child-wrapper:hover.right {
  right: -50px;
  top: calc(50% - 25px);
}
div.node div.insert-child-wrapper.default.right.conditional,
div.node div.insert-child-wrapper:hover.right.conditional,
div.trigger div.insert-child-wrapper.default.right.conditional,
div.trigger div.insert-child-wrapper:hover.right.conditional,
div.control-node div.insert-child-wrapper.default.right.conditional,
div.control-node div.insert-child-wrapper:hover.right.conditional {
  right: -57px;
  top: calc(50% - 25px + 7px);
}
div.node div.insert-child-wrapper.default.left,
div.node div.insert-child-wrapper:hover.left,
div.trigger div.insert-child-wrapper.default.left,
div.trigger div.insert-child-wrapper:hover.left,
div.control-node div.insert-child-wrapper.default.left,
div.control-node div.insert-child-wrapper:hover.left {
  left: -50px;
  top: calc(50% - 25px);
}
div.node div.insert-child-wrapper.default.left.conditional,
div.node div.insert-child-wrapper:hover.left.conditional,
div.trigger div.insert-child-wrapper.default.left.conditional,
div.trigger div.insert-child-wrapper:hover.left.conditional,
div.control-node div.insert-child-wrapper.default.left.conditional,
div.control-node div.insert-child-wrapper:hover.left.conditional {
  left: -57px;
  top: calc(50% - 25px + 7px);
}
div.node div.insert-child-wrapper.default.top,
div.node div.insert-child-wrapper:hover.top,
div.trigger div.insert-child-wrapper.default.top,
div.trigger div.insert-child-wrapper:hover.top,
div.control-node div.insert-child-wrapper.default.top,
div.control-node div.insert-child-wrapper:hover.top {
  top: -50px;
  left: calc(50% - 25px);
}
div.node div.insert-child-wrapper.default.top.conditional,
div.node div.insert-child-wrapper:hover.top.conditional,
div.trigger div.insert-child-wrapper.default.top.conditional,
div.trigger div.insert-child-wrapper:hover.top.conditional,
div.control-node div.insert-child-wrapper.default.top.conditional,
div.control-node div.insert-child-wrapper:hover.top.conditional {
  top: -57px;
  left: calc(50% - 25px + 7px);
}
div.node div.insert-child-wrapper.default .insert-child,
div.node div.insert-child-wrapper:hover .insert-child,
div.trigger div.insert-child-wrapper.default .insert-child,
div.trigger div.insert-child-wrapper:hover .insert-child,
div.control-node div.insert-child-wrapper.default .insert-child,
div.control-node div.insert-child-wrapper:hover .insert-child {
  height: 28px;
  width: 28px;
  font-size: 0.75rem;
}

div.control-node .control-node-container {
  background-color: white;
  transform: rotate(45deg);
  border: 1px solid #c0c0c0;
  padding: 15px 14px;
  width: 40px;
  height: 40px;
}
div.control-node .control-node-container .node-content {
  transform: rotate(-45deg) translate(-7px, -7px);
}
div.control-node .warnings-errors-control-node .dropdown-icons .errors,
div.control-node .warnings-errors-control-node .dropdown-icons .warnings {
  position: absolute;
  top: calc(100% - 45px);
  right: -20px;
  cursor: pointer;
}

.trigger-radiobutton:checked ~ .custom-control-label::before {
  background-color: #a0b0c0;
  border-color: #a0a0a0;
}

.warnings-errors .dropdown-icons .errors,
.warnings-errors .dropdown-icons .warnings {
  position: absolute;
  top: calc(50% - 27px);
  right: -21px;
  cursor: pointer;
}

div.trigger::before {
  content: "";
  position: absolute;
  left: 1px;
  right: 1px;
  top: 1px;
  bottom: 1px;
  background-image: linear-gradient(45deg, transparent 0%, white 0% 90%, transparent 90%);
}

.node-content {
  position: relative;
}
.node-content hr {
  border-top-color: #eee;
}
.node-content .node-subtitle {
  font-size: 0.7rem;
}
.node-content .node-subtitle label {
  font-size: 0.9em;
  color: #909090;
}
.node-content .node-subtitle i {
  font-size: 0.8rem;
}
.node-content .control-node-subtitle {
  position: absolute;
  top: -45px;
}
.node-content .control-node-subtitle div.control-node-subtitle-message {
  border: 1px solid #c0c0c0;
  font-size: 10px;
  color: #808080;
  font-weight: normal;
  line-height: 1.15;
}
.node-content .control-node-subtitle div.control-node-subtitle-message > div {
  min-width: 5.5rem;
  max-width: 7rem;
  white-space: normal;
  max-height: 2.4rem;
  overflow: hidden;
}
.node-content .node-icon-app .select-node-checkbox {
  border: 1px solid #ddd;
  width: 1.9rem;
  height: 1.9rem;
  margin-top: 0.35rem;
  margin-left: 0.35rem;
}
.node-content .node-icon-app .node-icon {
  border: 1px solid #c0c0c0;
  width: 1.4rem;
  height: 1.4rem;
  font-size: 12.5px;
  padding-top: 0.32rem;
  margin-top: 0.6rem;
  margin-left: -0.2rem;
  border-radius: 4px;
  background-color: white;
}
.node-content .node-icon-app .node-icon i {
  color: #909090;
}
.node-content .iterable-legend {
  position: absolute;
}
.node-content .iterable-legend span {
  border: 1px solid #c0c0c0;
}

.badge-container .badge-checkbox {
  display: none;
}

.badge-container.selected .badge-checkbox {
  display: block;
}

.badge-container.selected .badge-icon {
  display: none;
}

.badge-container:hover .badge-checkbox {
  display: block;
}

.badge-container:hover .badge-icon {
  display: none;
}

.execution-message {
  background: #fff;
  border: 1px solid #e0e5ea;
  padding: 0.75rem;
  color: #40454a;
  max-width: 14rem;
  color: #40454a;
  border-radius: 10px;
  max-width: 12rem;
  font-size: 0.67rem;
  line-height: 1.5;
  top: 0px;
  right: 0px;
  left: auto;
}

.execution-message.execution-trigger {
  margin-top: 1.5rem !important;
  margin-left: -1.5rem !important;
}

.execution-message.execution-node {
  margin-right: -10rem;
}

.react-flow__node:only-child:has(.node-idx), .react-flow__node:nth-last-child(2):has(.node-idx) {
  padding-bottom: 2.5rem;
  padding-right: 1.5rem;
}

.react-flow__handle {
  z-index: 1001 !important;
  min-height: 16px !important;
  min-width: 16px !important;
  width: 16px !important;
  height: 16px !important;
  background: none !important;
  border: none !important;
  display: flex !important;
  align-items: center;
  justify-content: center;
}
.react-flow__handle.react-flow__handle-top {
  top: -9px;
}
.react-flow__handle.react-flow__handle-bottom {
  bottom: -9px;
}
.react-flow__handle.react-flow__handle-right {
  right: -9px;
}
.react-flow__handle.react-flow__handle-left {
  left: -9px;
}

.react-flow__handle::after {
  content: " ";
  position: absolute;
  background-color: #c0c0c0;
  display: inline-block;
  border-radius: 50%;
  width: 6px;
  height: 6px;
  border: 1px solid #fff;
  top: 5px;
  left: 5px;
}

.cta-iframe-viewer,
.cta-create-node {
  cursor: pointer;
}
.cta-iframe-viewer:hover,
.cta-create-node:hover {
  text-decoration: underline;
}

.cta-delete-node {
  cursor: pointer;
  float: right;
  border: 1px solid #f0f0f0;
  color: #a0a0a0;
  padding: 3px;
  margin-top: 5px;
}

