#use-cases {
  height: 100vh;
  width: 100%;
  overflow-y: auto;
}
#use-cases #use-cases-container {
  position: relative;
  top: 17%;
  margin-top: -5.5rem;
}
#use-cases #use-cases-container img.workix-logo {
  height: 2.75rem;
}
#use-cases #use-cases-container #use-cases-title span {
  font-size: 1.15em;
  color: rgba(255, 255, 255, 0.8784313725);
}
#use-cases #use-cases-container .flow {
  margin-left: 0.27rem;
  margin-right: 0.27rem;
  border: 1px solid #d2dae0;
}
#use-cases #use-cases-container .flow div.avatar img {
  width: 4rem;
}
#use-cases #use-cases-container .flow .flow-apps i {
  color: #888;
}
#use-cases #use-cases-container .flow .flow-apps span {
  color: #777;
}

