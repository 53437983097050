.text {
  .text-content {
    overflow: hidden;
    padding: 5px 12px 5px 7px;
    font-style: italic;
    font-size: 10px;
    line-height: 1.2;
    color: #a0a0a0;
    border: 1px dashed #c0c0c0;
    white-space: pre-wrap;

    .badge-container {
      position: absolute;
      top: -24px;
      right: calc(100% - 27px);
      width: 2.8rem;
      height: 2.8rem;

      .badge-checkbox {
        display: none;
        border: 1px solid #ddd;
        width: 1.9rem;
        height: 1.9rem;
        margin-top: 0.2rem;
        margin-left: 0.2rem;
      }

      &:hover {
        .badge-checkbox {
          display: block;
        }

        .badge-icon {
          display: none;
        }
      }

      &.selected {
        .badge-checkbox {
          display: block;
        }

        .badge-icon {
          display: none;
        }
      }
    }
  }

  .text-resizer {
    display: none;
    margin-top: -0.7rem;
    margin-left: -0.7rem;
    background: white;

    i {
      transform: scaleX(-1);
      font-size: 0.72em;
      color: #a0a0a0;
      padding: 0.4em;
      background: #f8fafe;
      border-radius: 50%;
    }
  }

  &:hover {
    .text-resizer {
      display: block;
    }
  }
}
