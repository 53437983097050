$toolbox-bg: #f9faff;
$component-bg: #fff;
$component-border: #ccd0d5;
$toolbox-padding-x: 1.25rem;

#toolbox-container {
  position: relative;
  width: 367px;
  min-width: 367px;
  max-width: 367px;
  background: $toolbox-bg;
  border-top: 1px solid #dae5f0;
  border-right: 1px solid #d2dae0;
  transition: min-width 0.3s, max-width 0.3s, width 0.3s;

  &.collapsed {
    width: 70px !important;
    min-width: 70px !important;
    max-width: 70px !important;
    transition: min-width 0.25s, max-width 0.25s, width 0.25s;
  }

  &.data-section {
    width: 820px;
    min-width: 820px;
    max-width: 820px;
    transition: min-width 0.4s, max-width 0.4s, width 0.4s;
    #toolbox-main {
      width: calc(820px - 70px);
      min-width: calc(820px - 70px);
      max-width: calc(820px - 70px);
    }
  }

  #toolbox-sidenav {
    width: 70px;
    min-width: 70px;
    max-width: 70px;
    padding-top: 17px;
    border-right: 1px solid #dae5f0;
    background: #2065b0;
    box-sizing: border-box;
    overflow-y: auto;

    .btn-app {
      width: 50px;
      height: 75px;
      color: #ffffffb0;
      padding-top: 0.25rem;

      i {
        font-size: 1.5rem;
        margin-bottom: 0.5rem;
      }

      label {
        font-size: 0.72rem;
      }
    }

    .btn-app:hover,
    .btn-app.active {
      color: #fff;
    }

    .btn-app.active {
      font-weight: bold;
    }
  }

  #toolbox-main {
    height: calc(100% - 64px);
    width: 297px;
    min-width: 297px;
    max-width: 297px;

    #toolbox-actions {
      height: calc(100% - 64px);
      padding-top: 1.4rem;
      padding-bottom: 1.7rem;
      padding-left: $toolbox-padding-x;
      padding-right: $toolbox-padding-x;
      overflow-y: auto;
      scrollbar-gutter: stable both-edges;

      #toolbox-search {
        padding-bottom: 1.35rem;

        input {
          border: 1px solid $component-border;
          font-size: 0.95em;
          transition: 0.4s;
          padding-top: 0.37rem;
          padding-bottom: 0.37rem;
          background: $component-bg;

          &:focus {
            background: #fff;
          }
        }

        .search-icon {
          font-size: 0.9rem;
          margin-top: -1.55rem;
        }

        .search-cta {
          position: relative;
          left: 100%;
          width: 3.5rem;
          margin-left: -3.77rem;
          margin-top: -0.9rem;

          .search-close,
          .search-filters {
            cursor: pointer;
            font-size: 0.91rem;
            color: #90959a;
          }

          .search-close:hover,
          .search-filters:hover {
            color: #70757a;
          }

          .search-filters.active {
            color: #2080e5;
          }

          .search-close {
            display: none;

            &:hover {
              display: block;
            }
          }
        }

        &:focus-within i.search-close {
          display: block;
        }
      }

      #toolbox-tabs {
        padding-top: 1rem;
        width: 100%;

        .tab-button {
          background: $component-bg;
          color: #758085;
          transition: background 0.3s;
          border: 1px solid $component-border;
          font-size: 0.96em;
          padding: 0.35rem 1rem;
          margin-right: 0.25rem;
          user-select: none;

          i {
            display: none;
          }

          &.active {
            background: #2080e5;
            color: #fff;

            i {
              display: inline-block;
              color: #fff;
            }
          }

          &:hover {
            border-color: #b0bac0;
          }
        }
      }

      .suggestion-divider {
        width: 100%;
        border-top: 1px solid #ddd;
        margin-top: 0.25rem;
        margin-bottom: 0.7rem;
      }

      #toolbox-suggestions {
        background: #fffafa;

        .dropdown-header {
          span {
            color: #777;

            i {
              color: #f0b000;
            }
          }
        }

        ul.list-group {
          border: none;
          padding-left: 1.2rem;
          padding-right: 1.2rem;
        }
      }

      div.collapse {
        position: relative;
        z-index: 5;

        background: $toolbox-bg;
      }

      div.collapse-divider {
        padding-top: 0rem;
        padding-bottom: 0.25rem;
        padding-left: 0.2rem;
        padding-right: 0.2rem;

        label {
          font-size: 0.88em;
          font-style: italic;
          color: #70757a;
        }
      }

      .action-element {
        .btn-action {
          cursor: pointer;
          border-color: $component-border;
          background-color: $component-bg;
          color: #60656a;

          &:hover {
            background: #fff;
            transform: translate(2px, -2px);

            &.h5 {
              color: #20252a;
              font-weight: bold;
            }
          }

          i.app-icon {
            margin-left: 0.3rem;
            margin-right: 1rem;
          }

          .action-title {
            padding-right: 0;
            color: #455a65;
            font-weight: bold;
            font-size: 0.95em;
            line-height: 1.45;

            span.action-note {
              color: #aaa;
              font-size: 0.8em;
            }
          }

          .action-subtitle {
            label {
              font-size: 0.9em;
              color: #999;
            }
          }

          .action-trigger-label {
            color: #a0a5b0;
            font-size: 0.87em;
            font-weight: normal;
            margin-left: 0.3rem;
          }
        }
      }

      span.search-title {
        color: #555;
      }

      ul.actions-list {
        div.hidden-actions {
          a.hidden-actions-title {
            border: none;
            color: #a0a0a0;
            font-size: 0.9em;
            margin-left: -0.75rem;

            i {
              color: #b4b4b4;
              font-size: 0.9em;
            }
          }
        }
      }

      div.section-title {
        margin-top: 0.6rem;
        margin-bottom: 0.6rem;

        label {
          font-size: 0.9em;
          color: #70757a;
        }
      }

      div.apps {
        width: 48.5%;
        margin-bottom: 0.5rem;

        &.first-col {
          margin-right: 3%;
        }

        &.suggestions {
          width: 100%;
          margin-top: 1rem;
          margin-bottom: 0rem;

          .btn-category {
            padding-top: 0.8rem;
            padding-bottom: 0.4rem;
          }

          .category-icon {
            font-size: 1.55em;
          }

          .category-title {
            margin-top: 0.25rem;
          }
        }

        .btn-category {
          border: 1px solid $component-border;
          font-size: 0.9em;
          padding-right: 1.25rem;
          padding-left: 1.25rem;
          padding-top: 1.27rem;
          padding-bottom: 0.8rem;
          background: $component-bg;
          color: #758085;
          transition: background 0.3s, border-color 0.3s;
          font-size: 0.97em;
          margin-right: 0.25rem;
          height: 100%;

          i {
            display: none;
            color: #959aa0;
          }

          &[aria-expanded="true"] {
            background: #2080e5 !important;

            .category-icon {
              color: #fff !important;
            }

            .category-title {
              color: #fff !important;
            }
          }

          &:hover {
            background: #fff;
            border-color: #a5aab0;

            .category-icon {
              color: #777;
            }

            .category-title {
              color: #222;
            }
          }
        }

        .category-icon {
          color: #80858a;
          font-size: 1.75em;
          text-align: center;
          width: 100%;
        }

        .category-title {
          color: #454a50;
          font-size: 0.9em;
          margin-top: 0.5rem;
          text-align: center;

          span {
            line-height: 1.15;
            display: inline-block;
            vertical-align: top;
          }
        }
      }

      .no-results {
        position: absolute;
        font-size: 0.9em;
        margin-top: 5.2rem;
        z-index: 0;
      }
    }

    #toolbox-ideas {
      height: 100%;
      padding-top: 1.4rem;
      padding-bottom: 1.7rem;
      padding-left: $toolbox-padding-x;
      padding-right: $toolbox-padding-x;
      overflow-y: auto;
      scrollbar-gutter: stable both-edges;

      div.section-title {
        margin-top: 0.6rem;
        margin-bottom: 0.7rem;

        label {
          font-size: 1em;
          color: #70757a;
        }
      }

      .flow-sample {
        border-color: $component-border;
        background-color: $component-bg;
        color: #60656a;

        .action-title {
          padding-right: 0;
          color: #455a65;
          font-weight: bold;
          font-size: 0.95em;
          line-height: 1.45;

          span.action-note {
            color: #aaa;
            font-size: 0.8em;
          }
        }

        .action-subtitle {
          label {
            font-size: 0.9em;
            color: #999;
          }
        }

        &:hover {
          background: #fff;
          transform: translate(2px, -2px);

          &.h5 {
            color: #20252a;
            font-weight: bold;
          }
        }

        .flow-preview {
          border: 1px solid $component-border;
        }

      }
    }
  }

  #collapse-toolbox {
    background-color: white;
    width: 25px;
    height: 25px;
    border: 1px solid #b0b5c0;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 10px;
    right: -12px;
    z-index: 1000;
    cursor: pointer;

    &:hover {
      background-color: #2080e5;
      color: white;
      border-color: white;
    }
  }

  .more-actions-wrapper {
    padding-left: $toolbox-padding-x + 0.5rem;
    padding-right: $toolbox-padding-x + 0.5rem;

    .more-actions-divider {
      width: 100%;
      border-top: 1px solid #ddd;
      margin-bottom: 1.1rem;
    }
  }
}

#app-store {
  height: 530px;
  overflow: hidden;

  #search-bar {
    input {
      border: 1px solid #d0deda;
    }

    i {
      font-size: 1rem;
      margin-top: -1.5rem;
    }
  }

  #toolbox-apps-tab {
    border-bottom: 2px solid #dee2e6;

    button {
      flex: 1;
      color: #777;
      background: none;
      border: 0;
      border-bottom: 2px solid transparent;
      transition: border-bottom-color 0.3s, color 0.3s;

      &.active {
        border-bottom-color: #2080e5;
      }
    }

    button.active,
    button:hover {
      color: #2080e5;
    }
  }

  #app-list {
    height: 400px;
    overflow-y: auto;

    ul {
      div.app-element {
        div.apps-group {
          background: $toolbox-bg;
          font-size: 0.85em;
          left: 1.1rem;
          color: #757a80;
        }

        li.app-item {
          padding-left: 1.4rem;

          div.app-icon {
            i {
              font-size: 1.1rem;
            }
          }

          div.app-title {
            .app-label {
              font-size: 0.81rem;
              color: #40454a;
            }
          }

          div.app-actions {
            button.app-request-access {
              width: 4.5rem;
              line-height: 1.25;
            }

            button.app-install {
              width: 4.5rem;
              line-height: 1.25;
            }

            div.app-uninstall {
              button {
                border: none;
                background-color: white;

                i {
                  color: #777;
                }
              }
            }

            button.app-requested {
              width: 4.5rem;
              line-height: 1.25;
              opacity: 1;
            }
          }
        }
      }
    }
  }
}
