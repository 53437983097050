.node-annotation {
  background-color: white;
  border: 1px solid #c0c0c0;
  border-radius: 5px;
  font-style: italic;
}
.node-annotation .node-annotation-content {
  padding: 8px 14px;
  max-width: 12rem;
  overflow: hidden;
  color: black;
  white-space: pre-wrap;
}
.node-annotation .node-annotation-content .badge-container {
  position: absolute;
  top: -24px;
  right: calc(100% - 27px);
  width: 2.8rem;
  height: 2.8rem;
}
.node-annotation .node-annotation-content .badge-container .badge-checkbox {
  display: none;
  border: 1px solid #ddd;
  width: 1.9rem;
  height: 1.9rem;
  margin-top: 0.2rem;
  margin-left: 0.2rem;
}
.node-annotation .node-annotation-content .badge-container:hover .badge-checkbox {
  display: block;
}
.node-annotation .node-annotation-content .badge-container:hover .badge-icon {
  display: none;
}
.node-annotation .node-annotation-content .badge-container.selected .badge-checkbox {
  display: block;
}
.node-annotation .node-annotation-content .badge-container.selected .badge-icon {
  display: none;
}
.node-annotation .node-annotation-resizer {
  opacity: 0;
  margin-top: -0.7rem;
  margin-left: -0.7rem;
  background: white;
  padding: 0.5rem;
}
.node-annotation .node-annotation-resizer i {
  transform: scaleX(-1);
  font-size: 0.72em;
  color: #a0a0a0;
  padding: 0.4em;
  background: #f8fafe;
  border-radius: 50%;
}
.node-annotation .node-annotation-resizer:hover {
  opacity: 1;
}

