.color-picker-preview {
  padding: 5px;
  background: #fff;
  border-radius: 0.3125rem;
  border: 0.0625rem solid #e2e5f0;
  display: inline-block;
  cursor: pointer;
}
.color-picker-preview .color {
  width: 50px;
  height: 25px;
  border-radius: 2px;
}

.color-picker-popover {
  position: absolute;
  z-index: 2;
}
.color-picker-popover .color-picker-close {
  position: fixed;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
}
.color-picker-popover .twitter-picker input[id^=rc-editable-input] {
  display: none;
}

