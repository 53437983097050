#executions-board {
  position: relative;
  width: 367px;
  min-width: 367px;
  max-width: 367px;
  background: #f9faff;
  border-top: 1px solid #dae5f0;
  border-right: 1px solid #d2dae0;
  transition: min-width 0.3s, max-width 0.3s, width 0.3s;

  &.collapsed {
    width: 0;
    min-width: 0;
    max-width: 0;
    transition: min-width 0.25s, max-width 0.25s, width 0.25s;
  }

  #executions-board-close {
    background-color: white;
    width: 25px;
    height: 25px;
    border: 1px solid #b0b5c0;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 10px;
    left: -12px;
    z-index: 1000;
    cursor: pointer;

    &:hover {
      background-color: #2080e5;
      color: white;
      border-color: white;
    }
  }
}
