#node-form .card-header #flip-icon {
  padding: 0.3rem 1rem 0.3rem 1.05rem;
  font-size: 0.9rem;
  border-radius: 1.5rem;
}
#node-form .card-header #flip-icon i {
  font-size: 1.05em;
  margin-right: 0.3rem;
}

#node-form-details * {
  color: white !important;
}
#node-form-details .card-header #flip-details-icon {
  cursor: pointer;
  font-size: 1.4rem;
}
#node-form-details #flow-samples {
  padding: 1rem 2rem !important;
}
#node-form-details #flow-samples .insert-case-button {
  background-color: white !important;
  color: #2080e5 !important;
  border: 1px solid white !important;
}
#node-form-details #flow-samples .insert-case-button:hover {
  background-color: #2080e5 !important;
  color: white !important;
}

.node-form-title div {
  color: #8a8c90;
  font-size: 1rem;
  margin-top: 0.15rem;
  font-weight: normal;
}
.node-form-title .caption {
  font-size: 0.9em;
  font-weight: normal;
  font-style: italic;
  color: rgba(255, 255, 255, 0.9411764706);
}
.node-form-title #help-message {
  margin-left: -0.4rem;
}
.node-form-title #app-detail {
  color: #8a8c90 !important;
  font-size: 0.7em;
  border-radius: 1rem;
  background: #f6faff;
  border: 1px solid #eee;
}
.node-form-title #app_detail,
.node-form-title #app_detail_icon {
  color: #8a8c90 !important;
}

