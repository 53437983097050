#tables {
  width: 100%;
  height: 100%;
  overflow: hidden;
}
#tables #tables-header {
  position: relative;
  margin-right: 2rem;
}
#tables #tables-header #collection-tabs {
  overflow-x: auto;
}
#tables #tables-header #collection-tabs .collection-tab {
  cursor: pointer;
  background-color: #fff;
  border: 1px solid #ddd;
  color: #6a7075;
  transition: background-color 0.3s, color 0.3s;
  max-width: 16rem;
}
#tables #tables-header #collection-tabs .collection-tab:hover {
  background-color: #fff;
  color: #111;
}
#tables #tables-header #collection-tabs .collection-tab.active {
  background-color: #2080e5;
  color: #fff;
  font-weight: bold;
}
#tables #tables-header #collection-tabs .collection-tab.active .tab-icon {
  color: #fff !important;
}
#tables #tables-header #collection-tabs .collection-tab .collection-options .collection-options-content .dropdown-item:not(:last-child) {
  border-right: 1px solid #ddd;
}
#tables #tables-header #collection-tabs #add-collection-tab {
  position: relative;
}
#tables #tables-header #collection-tabs #add-collection-tab #create-table-dropdown span {
  color: #6a7075;
  width: 5.7rem;
}
#tables #tables-header #collection-tabs #add-collection-tab #create-table-dropdown-content {
  position: fixed;
  z-index: 4000 !important;
}
#tables #tables-header #collection-tabs #add-collection-tab #create-table-dropdown-content > div {
  position: absolute;
}
#tables #tables-body {
  margin-top: 1.2rem;
}
#tables #tables-body #table-title {
  position: relative;
  z-index: 100;
}
#tables #tables-body #tables-board #table-controls {
  margin-top: -3.1rem !important;
}
#tables #tables-body #tables-board #table-controls #table-search-input > div {
  border: 1px solid #dadde0;
}
#tables #tables-body #tables-board #table-controls #table-search-input input {
  width: 10rem;
}
#tables #tables-body #tables-board #table-controls #table-search-input i {
  cursor: pointer;
}
#tables #tables-body #tables-board #table-controls #table-search-button {
  width: 2.2rem;
}
#tables #tables-body #tables-board #table-controls #table-control-buttons button#table-filter-button {
  min-width: "2.2rem";
}
#tables #tables-body #tables-board #table-controls #table-control-buttons #dropdown-options {
  width: 2.2rem;
}
#tables #tables-body #tables-board #collection-table {
  position: relative;
  overflow-y: auto;
  overflow-x: auto;
  width: calc(100% - 10px);
}
#tables #tables-body #tables-board #collection-table #loading-content {
  width: calc(100% - 100px);
  height: calc(100% - 200px);
}
#tables #tables-body #tables-board #collection-table table {
  width: auto;
}
#tables #tables-body #tables-board #collection-table table th {
  padding-top: 0rem !important;
  padding-bottom: 0rem !important;
  height: 36px !important;
}
#tables #tables-body #tables-board #collection-table table td {
  height: 36px !important;
  padding-top: 0rem !important;
  padding-bottom: 0rem !important;
}
#tables #tables-body #tables-board #collection-table table tbody {
  border-top: 2px solid #000;
  background-color: #fff;
}
#tables #tables-body #tables-board #collection-table table th.table-column {
  position: relative;
  min-width: 210px;
  max-width: 450px;
  height: 40px;
}
#tables #tables-body #tables-board #collection-table table .table-column-content {
  max-width: 100%;
}
#tables #tables-body #tables-board #collection-table table .table-column-content .column-name {
  max-width: 300px;
}
#tables #tables-body #tables-board #collection-table table .table-column-content .column-name span {
  font-size: 0.95em;
}
#tables #tables-body #tables-board #collection-table table .table-column-content .column-type {
  color: #858a90;
}
#tables #tables-body #tables-board #collection-table table .table-column-content .column-options-icon {
  cursor: pointer;
  font-size: 0.8em;
}
#tables #tables-body #tables-board #collection-table table .dropdown-column-settings {
  position: absolute;
  z-index: 1000;
}
#tables #tables-body #tables-board #collection-table table th.add-column {
  cursor: pointer;
}
#tables #tables-body #tables-board #collection-table table th.add-column .dropdown-header {
  color: #808590;
}
#tables #tables-body #tables-board #collection-table table td.folio-column {
  background: #f8fafe;
}
#tables #tables-body #tables-board #collection-table table td.folio-column span {
  cursor: pointer;
}
#tables #tables-body #tables-board #collection-table table td.table-record {
  max-width: 450px;
  position: relative;
  background-color: #fff;
}
#tables #tables-body #tables-board #collection-table table td.table-record .file-field {
  cursor: pointer;
  border-radius: 5px;
}
#tables #tables-body #tables-board #collection-table table td.table-record .file-field .file-field-content {
  border: 1px solid #e0e0e0;
  width: 75%;
  border-radius: 0.25rem;
  cursor: pointer;
}
#tables #tables-body #tables-board #collection-table table td.table-record .file-field .file-field-content i {
  font-size: 1.2em;
}
#tables #tables-body #tables-board #collection-table table td.table-record .boolean-field {
  cursor: pointer;
  border-radius: 5px;
}
#tables #tables-body #tables-board #collection-table table td.table-record .boolean-field input {
  cursor: pointer;
}
#tables #tables-body #tables-board #collection-table table td.table-record .collection-field {
  cursor: pointer;
  border-radius: 5px;
}
#tables #tables-body #tables-board #collection-table table td.delete-row-button {
  background-color: #fff;
  cursor: pointer;
}
#tables #tables-body #tables-board #collection-table table td.edit-row-button {
  background-color: #fff;
  cursor: pointer;
}
#tables #tables-body #tables-board #collection-pagination nav {
  overflow-x: auto;
}

