.edge {
  position: absolute;
  font-size: 10px;
  pointer-events: all;

  .dropdown {
    cursor: pointer;

    .edge-label {
      background-color: white;
      padding: 1px 5px;
      border-radius: 5px;
      font-style: italic;
      color: #8797a8;
    }

    .dropdown-menu {
      margin-top: -50px;

      .dropdown-item {
        font-size: 0.6rem;
      }
    }
  }
}

.edgebutton {
  position: absolute;
  top: 0;
  right: -25px;
  width: 20px;
  height: 20px;
  background: #eee;
  border: 1px solid #fff;
  cursor: pointer;
  border-radius: 50%;
  font-size: 8px;
}

.edgebutton:hover {
  box-shadow: 0 0 6px 2px rgba(0, 0, 0, 0.08);
}
