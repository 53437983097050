#settings {
  height: 100vh;
  width: 100%;
  overflow-y: auto;

  #start-settings {
    aside {
      border-radius: 10px;
    }

    #start-settings-header {
      border-bottom: 0.12rem solid #e7eaf3;
      background: #f7fafe;
      border-radius: 10px 10px 0px 0px;

      #accountNavbarDropdown {
        width: 16rem;
        top: 4rem;
      }
    }

    .back-button-flow {
      display: block;
      width: 2.3rem;
      height: 2.3rem;
      background-image: url("../../../../public/theme/svg/logos/logo-short.svg");
      background-position: center center;
      background-repeat: no-repeat;
      background-size: 2.3rem;
      cursor: default;
    }

    .navbar-vertical-content .nav-link {
      border: none !important;
      color: #6a7080 !important;
    }
  
    .navbar-vertical-content .nav-link.active,
    .navbar-vertical-content .nav-link:hover {
      color: #2080e5 !important;
    }
  }

  #billing {
    #billing-container {
      border: none;
      box-shadow: none;

      #plan-details {
        .name {
          font-weight: normal;
        }
      }
    }
  }

  #flows {
    #flow-list {
    }

    #no-flows {      
      font-size: 1rem;
      i {
        font-size: 2.7rem;
        color: #5a606a;
      }
    }
  }

  #external-apps {
    #external-apps-body {
      #integration-accounts {
        .integration-account {
          .integration-settings {
            button {
              font-size: 1em;
              border: none;

              i {
                color: #909090;
              }
            }
          }
        }
      }
    }
  }
}

