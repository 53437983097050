.step-form .step-icon {
  background: none;
  border: 10px solid #e0e5f0;
  width: 1.2rem;
  height: 1.2rem;
}

.step-form .step-icon::after {
  top: 2.05rem;
  left: 1.05rem;
  height: calc(100% - 1.4rem);
}

.step-form .step-icon::before {
  background-color: #fff;
  width: 1rem;
  height: 1rem;
  display: none;
}

.step-form .step-content {
  margin-top: -0.12rem;
}

.step-form.sub .step-icon {
  border: 8px solid #e0e5f0;
  width: 0.7rem;
  height: 0.7rem;
}

.step-form.sub .step-icon::after {
  top: 1.95rem;
  left: 0.94rem;
  height: calc(100% - 1.4rem);
}

.step-form.sub .step-item {
  padding-left: 0.1rem;
}

.step-form.sub .step-content {
  margin-top: -0.1rem;
  margin-left: 0.4rem;
}

.step-builder .step-icon {
  background: none;
  border: 10px solid #e0e5f0;
  width: 1.2rem;
  height: 1.2rem;
}

.step-builder .step-icon::after {
  top: 2.05rem;
  left: 0.8rem;
  height: calc(100% - 1.4rem);
}

.step-builder .step-icon::before {
  background-color: #fff;
  width: 1rem;
  height: 1rem;
  display: none;
}

.step-item.sub .step-icon {
  border: 8px solid #e0e5f0;
  width: 0.7rem;
  height: 0.7rem;
}

.step-item.sub .step-icon::after {
  top: 2rem;
  left: 0.7rem;
  height: calc(100% - 1.4rem);
}

.MuiAutocomplete-root .MuiInputBase-root {
  display: block;
  width: 100%;
  height: auto;
  padding: 0.19rem 1rem !important;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.6;
  color: #1e2022;
  background-color: #fff;
  background-clip: padding-box;
  border: 0.0625rem solid #e7eaf3;
  border-radius: 0.3125rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.MuiAutocomplete-root .MuiInputBase-root input {
  padding: 0.6rem 0.12rem !important;
}
.MuiAutocomplete-root .MuiOutlinedInput-notchedOutline {
  border: none !important;
}

.MuiFormControl-root.no-autocomplete input {
  caret-color: transparent;
  user-select: none;
  color: #1e2022;
  cursor: pointer;
}
.MuiFormControl-root.no-autocomplete input::selection {
  background: transparent;
  color: #1e2022;
}

.MuiFormControl-root.hidden-input input {
  color: transparent !important;
  opacity: 0 !important;
  user-select: none !important;
}

.MuiFormControl-root.date-input input {
  width: calc(100% - 55px) !important;
}

.attribute-button {
  position: relative;
  top: 0px;
  margin-top: -42px;
  float: right;
  margin-right: 60px;
  padding: 0.5rem;
  cursor: pointer;
  font-size: 1.1em;
}

.MuiInputBase-root.Mui-disabled {
  background-color: #f8fafd;
}

.select-field {
  font-family: Roboto, Helvetica, Arial, sans-serif;
  color: #555;
}

.select-field:invalid {
  color: #c0c0c0;
}

.select-field option {
  color: #555;
}

.custom-control-input.accent:focus ~ .custom-control-label::before {
  border-color: #f0b000 !important;
  box-shadow: 0 0 0 0.2rem rgba(240, 176, 0, 0.25) !important;
}

.custom-control-input.accent:checked ~ .custom-control-label::before {
  border-color: #f0b000 !important;
  background-color: #f0b000 !important;
}

.custom-control-input.accent:active ~ .custom-control-label::before {
  background-color: #fff !important;
  border-color: #f0b000 !important;
}

.custom-control-input.accent:not(:checked) ~ .custom-control-label::before {
  background-color: #fafafa;
}

.custom-control-input.accent:not(:checked) ~ .custom-control-label::after {
  background-color: #f0b000;
}

